import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Link from 'components/Link';
import Layout from 'components/Layout';
import SEO from 'components/Seo';

import 'locales/i18n';

const ConfirmationPage = ({ pageContext, location }) => {
  const { t, i18n } = useTranslation();
  const defaultLng = i18n.options.fallbackLng[0];
  i18n.changeLanguage(pageContext.lng || defaultLng);

  return (
    <Layout location={location}>
      <SEO
        title={t('confirmation.title')}
        lang={i18n.language || 'fr'}
        metaTitle={t('meta.title')}
        metaDescription={t('meta.description')}
      />

      <div className="row my-6">
        <div className="col-md-6 col-sm-8 mx-auto">
          <h1>{t('confirmation.title')}</h1>
          <p>{t('confirmation.description')}</p>
          <Link to="/" className="btn btn-primary btn-sm mt-2">
            &lsaquo; {t('confirmation.button')}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

ConfirmationPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
ConfirmationPage.defaultProps = {};

export default ConfirmationPage;
